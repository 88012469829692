import React from "react";
import { Group } from "react-aria-components";

import PhoneNumberField from "./PhoneNumberField";
import EmailField from "./EmailField";
import ChoicesField from "./ChoicesField";
import ShortTextField from "./ShortTextField";
import LongTextField from "./LongTextField";
import SSNField from "./SSNField";
import LocationField from "./LocationField";
import DateField from "./DateField";
import FullNameField from "./FullNameField";
import DropdownField from "./DropdownField";
import YesNoField from "./YesNoField";
import NumberField from "./NumberField";
import AttachmentField from "./AttachmentField";

import { FieldProps } from "./types";

const Field: React.FC<FieldProps> = ({
  field,
  register,
  setValue,
  control,
}) => {
  const renderField = () => {
    switch (field.type) {
      case "short_text":
        return <ShortTextField field={field} control={control} />;
      case "long_text":
        return <LongTextField field={field} control={control} />;
      case "ssn":
        return <SSNField field={field} control={control} />;
      case "phone_number":
        return <PhoneNumberField field={field} control={control} />;
      case "email":
        return <EmailField field={field} control={control} />;
      case "choices":
        return <ChoicesField field={field} control={control} />;
      case "location":
        return <LocationField field={field} control={control} />;
      case "date":
        return <DateField field={field} control={control} />;
      case "full_name":
        return <FullNameField field={field} control={control} />;
      case "dropdown":
        return <DropdownField field={field} control={control} />;
      case "yes_no":
        return <YesNoField field={field} control={control} />;
      case "number":
        return <NumberField field={field} control={control} />;
      case "attachment":
        return <AttachmentField field={field} control={control} />;
      case "group":
        return (
          <Group>
            {field.properties?.fields?.map((subField) => (
              <Field
                key={subField.ref}
                control={control}
                field={subField}
                register={register}
                setValue={setValue}
              />
            ))}
          </Group>
        );
      default:
        return null;
    }
  };

  return <div className="mb-4">{renderField()}</div>;
};

export default Field;

import React, { useState, useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type SSNFieldProps = {
  field: FieldConfig;
  control: Control;
};

const SSNField: React.FC<SSNFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;

  const validateSSN = (value: string): string | boolean => {
    const ssnDigits = value ? value.replace(/\D/g, "") : "";

    if (!ssnDigits) {
      return isRequired ? "SSN is required." : true;
    }

    if (ssnDigits.length !== 9) {
      return "SSN must be 9 digits.";
    }

    return true;
  };

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        validate: validateSSN,
        required: isRequired ? "SSN is required." : false,
      }}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error, isDirty, isValidating },
      }) => {
        const [showError, setShowError] = useState(false);
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
          if (isDirty && !isValidating && !isFocused) {
            setShowError(invalid);
          } else {
            setShowError(false);
          }
        }, [isDirty, isValidating, invalid, isFocused]);

        // Function to format SSN as ###-##-####
        const formatSSN = (input: string): string => {
          const digits = input.replace(/\D/g, "").slice(0, 9); // Remove non-digits, limit to 9 digits
          const parts = [];
          if (digits.length > 0) {
            parts.push(digits.slice(0, 3));
          }
          if (digits.length > 3) {
            parts.push(digits.slice(3, 5));
          }
          if (digits.length > 5) {
            parts.push(digits.slice(5, 9));
          }
          return parts.join("-");
        };

        const handleChange = (e: string) => {
          const formattedSSN = formatSSN(e);
          onChange(formattedSSN);
        };

        return (
          <TextField
            name={name}
            value={value || ""}
            onChange={handleChange}
            onBlur={() => {
              onBlur();
              setIsFocused(false);
            }}
            isRequired={isRequired}
            isInvalid={invalid}
          >
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <Input
              ref={ref}
              type="text"
              className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
              placeholder={field.properties?.placeholder || "###-##-####"}
              onFocus={() => setIsFocused(true)}
            />
            {showError && error?.message && (
              <FieldError>{String(error.message)}</FieldError>
            )}
          </TextField>
        );
      }}
    />
  );
};

export default SSNField;

import React, { useState } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type NumberFieldProps = {
  field: FieldConfig;
  control: Control;
};

const NumberField: React.FC<NumberFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        required: isRequired ? "This field is required." : false,
        validate: {
          isNumber: (value) => {
            if (!value && !isRequired) return true;
            const num = Number(value);
            return !isNaN(num) || "Please enter a valid number.";
          },
        },
      }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, error, isDirty },
      }) => {
        const [isFocused, setIsFocused] = useState(false);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          // Allow only numbers, decimal point, and minus sign
          const value = e.target.value.replace(/[^\d.-]/g, "");

          // Prevent multiple decimal points
          const parts = value.split(".");
          if (parts.length > 2) {
            return;
          }

          // Prevent multiple minus signs and only allow at start
          if (
            value.split("-").length > 2 ||
            (value.includes("-") && value.indexOf("-") !== 0)
          ) {
            return;
          }

          onChange(value);
        };

        return (
          <TextField name={name} isRequired={isRequired} isInvalid={invalid}>
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <Input
              ref={ref}
              type="text"
              inputMode="decimal"
              value={value || ""}
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                onBlur();
                setIsFocused(false);
              }}
              className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
              placeholder={field.properties?.placeholder || "Enter a number"}
            />
            {error && isDirty && !isFocused && (
              <FieldError>{error.message}</FieldError>
            )}
          </TextField>
        );
      }}
    />
  );
};

export default NumberField;

import React from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";
import { FieldConfig } from "./types";

type ShortTextFieldProps = {
  field: FieldConfig;
  control: Control;
};

const ShortTextField: React.FC<ShortTextFieldProps> = ({ field, control }) => {
  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        required: field.validations?.required
          ? "This field is required."
          : false,
      }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error, isDirty },
      }) => (
        <TextField>
          <FieldLabel text={field.title} />
          <FieldDescription content={field.properties?.description} />
          <Input
            name={name}
            ref={ref}
            value={value || ""}
            onChange={onChange}
            onBlur={onBlur}
            className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
            type="text"
            placeholder={
              field.properties?.placeholder || "Type your answer here"
            }
          />
          {error && isDirty && <FieldError>{error.message}</FieldError>}
        </TextField>
      )}
    />
  );
};

export default ShortTextField;

import React, { useState, useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import {
  DropZone,
  FileTrigger,
  Button,
  Text,
  Group,
} from "react-aria-components";
import type { DropEvent, FileDropItem } from "react-aria";
import {
  DocumentIcon,
  XMarkIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";

import { FieldComponentProps } from "./types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";
import { useFormConfig } from "components/Form/hooks";
import { xhrUpload } from "hooks/useXHRUpload";
import { useDragAndDrop } from "hooks/useDragAndDrop";

const DEFAULT_ALLOWED_TYPES = [".jpg", ".jpeg", ".png", ".pdf"];
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

type UploadStatus = "idle" | "uploading" | "success" | "error";

interface UploadState {
  status: UploadStatus;
  progress: number;
  error?: string;
}

interface AttachmentValue {
  file_uri: string;
  file_name: string;
  preview_blob?: string;
}

type FormValues = {
  [key: string]: AttachmentValue;
};

const AttachmentField: React.FC<FieldComponentProps<FormValues>> = ({
  field,
  control,
}) => {
  const supportsDragAndDrop = useDragAndDrop();

  const { config: formConfig } = useFormConfig();
  const isRequired = field.validations?.required || false;
  const allowedTypes =
    field.properties?.allowed_file_types || DEFAULT_ALLOWED_TYPES;
  const allowMultiple = field.properties?.allow_multiple_files || false;
  const maxFileSize = field.properties?.max_file_size || MAX_FILE_SIZE;

  const [uploadState, setUploadState] = useState<UploadState>({
    status: "idle",
    progress: 0,
  });
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const isImage = (file: File) => {
    return file.type.startsWith("image/");
  };

  const generatePreview = async (file: File) => {
    if (isImage(file)) {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result as string;
          setPreviewUrl(base64Data);
          resolve(base64Data);
        };
        reader.readAsDataURL(file);
      });
    }
    setPreviewUrl(null);
    return null;
  };

  const uploadFile = async (
    file: File,
  ): Promise<{ file_uri: string; file_name: string }> => {
    setUploadState({ status: "uploading", progress: 0 });

    try {
      const formData = new FormData();
      formData.append("file", file);

      const metadata = {
        form_id: formConfig.id,
        field_ref: field.ref,
        response_id: window.location.pathname.split("/").pop(),
      };
      formData.append("metadata", JSON.stringify(metadata));

      const response = await xhrUpload<{ file_uri: string; file_name: string }>(
        "/forms/uploads",
        formData,
        {
          onProgress: (progress) => {
            setUploadState((prev) => ({
              ...prev,
              progress,
            }));
          },
        },
      );

      setUploadState({ status: "success", progress: 100 });
      return response;
    } catch (error) {
      Sentry.captureException(error);
      setUploadState({
        status: "error",
        progress: 0,
        error: "Failed to upload file",
      });
      throw error;
    }
  };

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        required: isRequired ? "This field is required." : false,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleFilesFromDrop = async (e: DropEvent) => {
          const fileDropItems = e.items.filter(
            (item): item is FileDropItem => item.kind === "file",
          );

          try {
            const files = await Promise.all(
              fileDropItems.map((item) => item.getFile()),
            );
            const file = files[0];

            if (file.size > maxFileSize) {
              setUploadState({
                status: "error",
                progress: 0,
                error: "File size exceeds 10MB limit",
              });
              return;
            }

            const previewBlob = await generatePreview(file);
            const response = await uploadFile(file);
            onChange({
              ...response,
              preview_blob: previewBlob,
            });
          } catch (error) {
            console.error("Error handling file:", error);
          }
        };

        const handleFilesFromSelect = async (files: FileList | null) => {
          if (files?.length) {
            const file = files[0];

            if (file.size > maxFileSize) {
              setUploadState({
                status: "error",
                progress: 0,
                error: "File size exceeds 10MB limit",
              });
              return;
            }

            const previewBlob = await generatePreview(file);
            const response = await uploadFile(file);
            onChange({
              ...response,
              preview_blob: previewBlob,
            });
          } else {
            onChange(null);
            setPreviewUrl(null);
          }
        };

        const handleRemove = () => {
          onChange(null);
          setPreviewUrl(null);
          setUploadState({ status: "idle", progress: 0 });
        };

        const handleDropZoneClick = (e: React.MouseEvent) => {
          // Only trigger if clicking the drop zone itself, not its children
          if (e.target === e.currentTarget) {
            triggerRef.current?.click();
          }
        };

        // When the component mounts or value changes, set the preview URL
        useEffect(() => {
          if (value?.preview_blob) {
            setPreviewUrl(value.preview_blob);
          }
        }, [value]);

        return (
          <div className="mb-4">
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />

            <Text
              slot="description"
              className="mb-2 inline-block text-sm text-soil"
            >
              Allowed file types: {allowedTypes.join(", ")}
            </Text>

            {!value ? (
              <Group>
                <div onClick={handleDropZoneClick}>
                  <DropZone
                    onDrop={handleFilesFromDrop}
                    className="relative cursor-pointer rounded border border-dashed border-gray-400 bg-oat-50 p-8 text-center transition-colors hover:border-gray-600 hover:bg-oat-100"
                  >
                    {({ isHovered }) => (
                      <div
                        className={`flex flex-col items-center justify-center space-y-4 ${
                          isHovered ? "border-green-500" : ""
                        }`}
                      >
                        <CloudArrowUpIcon className="size-16 text-soil" />
                        <div className="flex flex-col items-center space-y-2">
                          <div>
                            <span className="font-bold">Choose file</span>
                            {supportsDragAndDrop && (
                              <>
                                <span> or </span>
                                <span className="font-bold">drop here</span>
                              </>
                            )}
                          </div>
                          <div className="text-sm text-soil">
                            Size limit:{" "}
                            {(maxFileSize / (1024 * 1024)).toFixed(0)}
                            MB
                          </div>
                        </div>
                        <FileTrigger
                          acceptedFileTypes={allowedTypes}
                          onSelect={handleFilesFromSelect}
                          allowsMultiple={allowMultiple}
                        >
                          <Button
                            ref={triggerRef}
                            className="absolute inset-0 h-full w-full opacity-0"
                          >
                            Open file browser
                          </Button>
                        </FileTrigger>
                      </div>
                    )}
                  </DropZone>
                </div>
              </Group>
            ) : (
              <div className="relative rounded border border-gray-200 p-4">
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="File preview"
                    className="mx-auto max-h-48 object-contain"
                  />
                ) : (
                  <div className="flex items-center justify-center">
                    <DocumentIcon className="h-12 w-12 text-soil" />
                  </div>
                )}
                <div className="mt-2 text-center text-soil">
                  {value.file_name}
                </div>
                <button
                  onClick={handleRemove}
                  className="absolute right-2 top-2 rounded-full bg-white p-1 text-soil hover:text-cantelope"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            )}

            {uploadState.status === "uploading" && (
              <div className="mt-2">
                <div className="h-2 w-full rounded-full bg-gray-200">
                  <div
                    className="h-2 rounded-full bg-cantelope transition-all"
                    style={{ width: `${uploadState.progress}%` }}
                  />
                </div>
              </div>
            )}

            {uploadState.status === "error" && (
              <Text slot="error" className="mt-2 text-sm text-red-500">
                {uploadState.error}
              </Text>
            )}

            {error && <FieldError>{error.message}</FieldError>}
          </div>
        );
      }}
    />
  );
};

export default AttachmentField;

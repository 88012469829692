import config from "config";
import * as Sentry from "@sentry/react";

interface ProgressCallback {
  (progress: number): void;
}

interface XHROptions {
  method?: string;
  headers?: Record<string, string>;
  onProgress?: ProgressCallback;
}

export const xhrUpload = async <T>(
  endpoint: string,
  formData: FormData,
  options: XHROptions = {},
): Promise<T> => {
  const url = `${config.apiEndpoint}/api/v1${endpoint}`;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Handle progress if callback provided
    if (options.onProgress) {
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          options.onProgress?.(progress);
        }
      });
    }

    xhr.addEventListener("load", () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        } catch (error) {
          Sentry.captureException(error);
          reject(new Error("Failed to parse response"));
        }
      } else {
        const error = new Error(`Upload failed with status ${xhr.status}`);
        Sentry.captureException(error, {
          extra: {
            status: xhr.status,
            response: xhr.responseText,
          },
        });
        reject(error);
      }
    });

    xhr.addEventListener("error", () => {
      const error = new Error("Network error occurred");
      Sentry.captureException(error);
      reject(error);
    });

    xhr.open(options.method || "POST", url);

    // Set credentials mode like in fetchPublicAPI
    xhr.withCredentials = true;

    // Apply headers from options
    const headers = {
      ...options.headers,
    };

    Object.entries(headers).forEach(([key, value]) => {
      if (value) xhr.setRequestHeader(key, value);
    });

    xhr.send(formData);
  });
};

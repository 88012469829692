import React from "react";
import { Link } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

import CalendlyEmbedModal from "components/CalendlyEmbedModal";
import { useFormTextSubstitution } from "components/Form/hooks";
import { useFormConfig } from "./hooks";
import { Screen as ScreenType } from "./types";

interface ScreenProps {
  screen: ScreenType;
  response?: any;
  error?: any;
  onStart?: () => void;
  isStartScreen?: boolean;
}

const Screen: React.FC<ScreenProps> = ({
  screen,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  response,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error,
  onStart,
  isStartScreen,
}) => {
  const posthog = usePostHog();
  const { config } = useFormConfig();
  const { substituteVariables } = useFormTextSubstitution();

  const title = substituteVariables(screen.title);
  const description = substituteVariables(screen.properties?.description || "");

  function handleClick() {
    if (isStartScreen) {
      posthog?.capture("forms_start_screen_button_click", {
        form_id: config.id,
        start_screen_ref: screen.ref,
      });
      onStart?.();
    } else {
      posthog?.capture("forms_end_screen_button_click", {
        form_id: config.id,
        end_screen_ref: screen.ref,
      });
    }
  }

  return (
    <div>
      <h1 className="mb-6 whitespace-pre-line text-2xl font-bold text-evergreen sm:text-3xl">
        {title}
      </h1>

      {description && (
        <p className="whitespace-pre-line text-lg">{description}</p>
      )}

      {(isStartScreen ||
        (screen.properties !== undefined && screen.properties.button)) && (
        <div className="mt-6">
          {/* Start screen button */}
          {isStartScreen && (
            <button
              onClick={handleClick}
              className={`w-full rounded bg-cantelope px-6 py-2 font-bold text-soil ring-offset-4 transition-all hover:bg-soil hover:text-cantelope hover:outline-soil focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cantelope disabled:pointer-events-none disabled:opacity-50 sm:w-auto`}
            >
              {screen.properties?.button?.button_text || "Start"}
            </button>
          )}

          {/* End screen button with URL */}
          {!isStartScreen && screen.properties?.button && (
            <Link
              className="flex w-full"
              to={substituteVariables(screen.properties.button.button_url)}
              onClick={handleClick}
            >
              <button
                className={`flex grow items-center justify-center whitespace-nowrap rounded bg-cantelope px-6 py-2 font-bold text-soil ring-offset-4 transition-all hover:bg-soil hover:text-cantelope hover:outline-soil focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cantelope disabled:pointer-events-none disabled:opacity-50 sm:grow-0`}
              >
                {screen.properties.button.button_text}
              </button>
            </Link>
          )}
        </div>
      )}

      {screen.properties !== undefined && screen.properties.calendly && (
        <div className="mt-6 border-t-2">
          {screen.properties.calendly.header_text && (
            <h2 className="mb-4 mt-4">
              {screen.properties.calendly.header_text}
            </h2>
          )}
          <CalendlyEmbedModal
            afterId={screen.properties.calendly.after_id}
            eventUrl={screen.properties.calendly.event_url}
            buttonContent={
              <div
                className="rounded-xl bg-cantelope-100 px-4 py-3 text-cantelope-500 outline outline-2 outline-cantelope transition-all hover:bg-cantelope hover:text-soil"
                id="cta_button_schedule_a_call"
              >
                {screen.properties.calendly.button_text ||
                  "Pick a date and time"}
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Screen;

import React from "react";
import { pick } from "lodash-es";
import { Helmet } from "react-helmet-async";

import Header from "components/Header";
import { useFetchPublicAPI } from "hooks/usePublicAPI";
import useQueryParams from "hooks/useQueryParams";
import Form from "components/Form";
import { FormConfig } from "components/Form/types";
import { useFormContext } from "components/Form/hooks";

function FormSkelly() {
  return (
    <div className="flex items-center justify-center">
      <div className="w-full animate-pulse space-y-6">
        <div className="h-10 w-1/3 rounded bg-gray-200"></div>
        <div className="space-y-4">
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
        </div>
        <div className="h-10 w-1/4 rounded bg-gray-200"></div>
      </div>
    </div>
  );
}

function useFormMetadata() {
  const [queryParams] = useQueryParams();

  const PARAMS = [
    "utm_medium",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  return pick(queryParams, PARAMS);
}

type FormLoaderProps = {
  responseId: string;
  formId: string;
};

const FormLoader: React.FC<FormLoaderProps> = ({ formId, responseId }) => {
  const metadata = useFormMetadata();
  const context = useFormContext();
  const { data, error, isLoading } = useFetchPublicAPI<{
    form_config: FormConfig;
    form_context: Record<string, unknown>;
    error?: string;
  }>({
    url: `/forms/${formId}/${responseId}`,
    options: {
      method: "POST",
      headers: { "content-type": "application/json" },
    },
    data: {
      metadata,
      context,
    },
  });

  if (isLoading) {
    return (
      <div>
        <Helmet>
          <title>Loading...</title>
        </Helmet>
        <Header />
        <div className="mx-auto my-6 max-w-xl sm:my-16">
          <FormSkelly />
        </div>
      </div>
    );
  }

  if (error || !data) {
    const errorType = error?.responseData?.error;
    let errorTitle = "Sorry, we could not find that form";
    let errorMessage =
      "Try clicking on the link exactly as it was sent to you.";

    if (errorType === "internal_server_error") {
      errorTitle = "Sorry, something went wrong";
      errorMessage =
        "We're experiencing technical difficulties. Please try again in a few minutes.";
    }

    return (
      <div>
        <Helmet>
          <title>Form Not Found</title>
        </Helmet>
        <Header />
        <div className="my-16 flex items-center justify-center text-center">
          <div>
            <h1 className="mb-4 font-serif">{errorTitle}</h1>
            <div className="space-y-2">
              <p>{errorMessage}</p>
              <p>
                If you still need help, please reach out to us at{" "}
                <a
                  href="mailto:support@after.com"
                  target="_blank"
                  className="underline decoration-dotted underline-offset-4 hover:decoration-solid"
                >
                  support@after.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{data.form_config.title}</title>
      </Helmet>
      <Form
        formConfig={data.form_config}
        formContext={data.form_context}
        metadata={metadata}
        responseId={responseId}
      />
    </>
  );
};

export default FormLoader;

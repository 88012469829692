import React, { useEffect, useState } from "react";
import {
  Controller,
  Control,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import {
  TextField,
  Input,
  Select,
  Label,
  Button,
  Popover,
  ListBox,
  SelectValue,
  ListBoxItem,
} from "react-aria-components";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type FullNameFieldProps = {
  field: FieldConfig;
  control: Control<FieldValues>;
};

const suffixOptions = ["Jr.", "Sr.", "I", "II", "III", "IV", "V"];

const RequiredIndicator = () => (
  <sup className="ml-0.5 text-xs font-normal text-red-400" aria-hidden="true">
    *
  </sup>
);

const FullNameField: React.FC<FullNameFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;

  const {
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  // Define field names using the ref as a prefix
  const prefix = field.ref;
  const firstNameField = `${prefix}.first_name`;
  const middleNameField = `${prefix}.middle_name`;
  const lastNameField = `${prefix}.last_name`;
  const suffixField = `${prefix}.suffix`;

  // Watch the field values to trigger validation
  const firstNameValue = watch(firstNameField);
  const lastNameValue = watch(lastNameField);

  useEffect(() => {
    if (isRequired || firstNameValue || lastNameValue) {
      let hasError = false;

      // Validate First Name
      if (isRequired || firstNameValue) {
        if (!firstNameValue) {
          setError(firstNameField, {
            type: "required",
            message: "First name is required.",
          });
          hasError = true;
        } else {
          clearErrors(firstNameField);
        }
      }

      // Validate Last Name
      if (isRequired || lastNameValue) {
        if (!lastNameValue) {
          setError(lastNameField, {
            type: "required",
            message: "Last name is required.",
          });
          hasError = true;
        } else {
          clearErrors(lastNameField);
        }
      }

      // Set or clear the error on the overall field
      if (hasError) {
        setError(prefix, {
          type: "manual",
          message: "Full name is incomplete.",
        });
      } else {
        clearErrors(prefix);
      }
    }
  }, [
    firstNameValue,
    lastNameValue,
    isRequired,
    setError,
    clearErrors,
    prefix,
    firstNameField,
    lastNameField,
  ]);

  return (
    <div className="mb-4">
      <FieldLabel text={field.title} />
      <FieldDescription content={field.properties?.description} />

      <div className="flex flex-col space-y-4">
        {/* First Name */}
        <Controller
          control={control}
          name={firstNameField}
          rules={{
            required: isRequired ? "First name is required." : false,
          }}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error, isDirty, isTouched },
          }) => {
            const [isFocused, setIsFocused] = useState(false);

            return (
              <TextField
                name={name}
                value={value || ""}
                isRequired={isRequired}
                isInvalid={invalid}
              >
                <Label className="text-md -mb-1 block font-semibold text-evergreen">
                  First Name{isRequired && <RequiredIndicator />}
                </Label>
                <Input
                  ref={ref}
                  type="text"
                  onChange={onChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    onBlur();
                    setIsFocused(false);
                  }}
                  className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
                />
                {error?.message && isDirty && isTouched && !isFocused && (
                  <FieldError>{String(error.message)}</FieldError>
                )}
              </TextField>
            );
          }}
        />

        {/* Middle Name */}
        <Controller
          control={control}
          name={middleNameField}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid },
          }) => (
            <TextField
              name={name}
              value={value || ""}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={invalid}
            >
              <Label className="-mb-1 block font-semibold text-evergreen">
                Middle Name
              </Label>
              <Input
                ref={ref}
                type="text"
                className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
              />
            </TextField>
          )}
        />

        {/* Last Name */}
        <Controller
          control={control}
          name={lastNameField}
          rules={{
            required: isRequired ? "Last name is required." : false,
          }}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error, isDirty, isTouched },
          }) => {
            const [isFocused, setIsFocused] = useState(false);

            return (
              <TextField
                name={name}
                value={value || ""}
                isRequired={isRequired}
                isInvalid={invalid}
              >
                <Label className="-mb-1 block font-semibold text-evergreen">
                  Last Name{isRequired && <RequiredIndicator />}
                </Label>
                <Input
                  ref={ref}
                  type="text"
                  onChange={onChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    onBlur();
                    setIsFocused(false);
                  }}
                  className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
                />
                {error?.message && isDirty && isTouched && !isFocused && (
                  <FieldError>{String(error.message)}</FieldError>
                )}
              </TextField>
            );
          }}
        />

        {/* Suffix */}
        <Controller
          control={control}
          name={suffixField}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid },
          }) => (
            <Select
              name={name}
              selectedKey={value || null}
              onSelectionChange={(key) => {
                if (key === "none") {
                  onChange(null);
                  return;
                }
                onChange(key);
              }}
              onBlur={onBlur}
              ref={ref}
              isInvalid={invalid}
            >
              <Label className="text-md -mb-1 block font-semibold text-evergreen">
                Suffix
              </Label>
              <Button className="flex w-full items-center justify-between rounded-none border-b border-gray-400 bg-transparent py-2 pl-0 text-left text-2xl font-bold text-soil transition-colors focus:border-cantelope focus:shadow-focus focus:outline-none">
                <SelectValue
                  className={({ isPlaceholder }) =>
                    `${isPlaceholder ? "opacity-0" : ""}`
                  }
                />
                <span aria-hidden="true">
                  <ChevronDownIcon className="h-4 w-5" />
                </span>
              </Button>
              <Popover className="entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out min-w-[var(--trigger-width)] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5">
                <ListBox className="p-1 focus:outline-cantelope-300">
                  {value && (
                    <ListBoxItem
                      id="none"
                      key="none"
                      className="cursor-pointer p-2 hover:bg-gray-100 hover:outline-cantelope-300 focus:outline focus:outline-2 focus:outline-cantelope-300"
                      textValue="No suffix"
                    >
                      <em>No suffix</em>
                    </ListBoxItem>
                  )}
                  {suffixOptions.map((suffix) => (
                    <ListBoxItem
                      key={suffix}
                      id={suffix}
                      className="cursor-pointer p-2 hover:bg-gray-100 hover:outline-cantelope-300 focus:outline focus:outline-2 focus:outline-cantelope-300"
                    >
                      {suffix}
                    </ListBoxItem>
                  ))}
                </ListBox>
              </Popover>
            </Select>
          )}
        />
      </div>

      {/* Display general error message if needed */}
      {errors[prefix]?.message && (
        <FieldError>{String(errors[prefix]?.message)}</FieldError>
      )}
    </div>
  );
};

export default FullNameField;

import { useState, useEffect } from "react";

/**
 * Returns `true` if the browser supports drag-and-drop on a fine pointer
 * device (mouse/trackpad). On mobile/touch (coarse pointer), it returns `false`.
 */
export function useDragAndDrop(): boolean {
  const [supportsDragAndDrop, setSupportsDragAndDrop] = useState(false);

  useEffect(() => {
    function canDragAndDrop(): boolean {
      if (typeof window === "undefined") {
        return false;
      }
      // If user's primary pointer is coarse (touch-based), assume no DnD.
      if (window.matchMedia?.("(pointer: coarse)").matches) {
        return false;
      }
      // Basic feature detection for drag-and-drop in the browser
      const div = document.createElement("div");
      return "draggable" in div || ("ondragstart" in div && "ondrop" in div);
    }

    setSupportsDragAndDrop(canDragAndDrop());
  }, []);

  return supportsDragAndDrop;
}

import React from "react";

import { useFormTextSubstitution } from "components/Form/hooks";

type FieldDescriptionProps = {
  content: string | undefined;
};

const FieldDescription: React.FC<FieldDescriptionProps> = ({ content }) => {
  const { substituteVariables } = useFormTextSubstitution();

  if (!content) {
    return null;
  }

  return (
    <div className="mb-4 whitespace-pre-line text-gray-600">
      {substituteVariables(content)}
    </div>
  );
};

export default FieldDescription;

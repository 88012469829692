import React from "react";
import { Button } from "react-aria-components";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { FieldConfig } from "./types";

interface DebugPanelProps {
  fields: FieldConfig[];
  currentFieldRef: string;
  onFieldSelect: (fieldRef: string) => void;
}

const DebugPanel: React.FC<DebugPanelProps> = ({
  fields,
  currentFieldRef,
  onFieldSelect,
}) => {
  const [isVisible, setIsVisible] = useLocalStorage(
    "form_debug_panel_visible",
    true,
  );

  if (!__DEV__) {
    return null;
  }

  return (
    <>
      {/* Toggle Button */}
      <div
        className={`fixed left-0 top-4 z-50 hidden transition-transform duration-200 lg:block ${
          isVisible ? "translate-x-64" : "translate-x-0"
        }`}
      >
        <Button
          onPress={() => setIsVisible(!isVisible)}
          className="rounded-r bg-white p-2 shadow-md hover:bg-gray-50"
        >
          {isVisible ? (
            <ChevronLeftIcon className="h-5 w-5" />
          ) : (
            <ChevronRightIcon className="h-5 w-5" />
          )}
        </Button>
      </div>

      {/* Debug Panel */}
      <div
        className={`fixed left-0 top-0 z-40 hidden h-full w-64 overflow-y-auto border-r border-gray-200 bg-white shadow-lg transition-transform duration-200 lg:block ${
          isVisible ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4">
          <h2 className="mb-4 font-bold text-gray-700">Debug Panel</h2>
          <div className="space-y-2">
            {fields.map((field) => (
              <Button
                key={field.ref}
                onPress={() => onFieldSelect(field.ref)}
                className={`w-full rounded px-3 py-2 text-left text-sm ${
                  currentFieldRef === field.ref
                    ? "bg-cantelope text-soil"
                    : "hover:bg-gray-100"
                }`}
              >
                <div className="font-medium">{field.title}</div>
                <div className="text-xs text-gray-500">
                  {field.type} ({field.ref})
                </div>
              </Button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DebugPanel;
